<template>
  <div class="content">

    <div class="bg">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 24px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 24px;font-weight: 500;color: #202033;">个体司机注册</span>
        </div>
        <div>
          <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="fanhui">返回</el-button>
        </div>
      </div>

      <div style="display: flex;justify-content: center;margin-top: 60px;">
        <div class="center">
          <div style="display: flex;align-items: center;justify-content: center;">
            <img src="@/assets/images/login/applogo1.png" alt="" style="width: 57px;height: 57px;margin-right: 10px;">
            <span style="font-size: 24px;font-weight: 700;color: #242832;">个体司机注册</span>
          </div>
          <div style="margin-top: 15px;text-align: center;">
            <img src="@/assets/images/login/applogo.png" alt="" style="width: 300px;height: 300px;">
          </div>
          <div style="margin-top: 15px;text-align: center;">
            <span style="font-size: 18px;font-weight: 400;color: #737c84;">请下载救援师傅（个人版）APP，进行注册和认证</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    data(){
      return{

      }
    },

    methods:{
      //点击返回按钮
      fanhui(){
        this.$router.go(-1);
      },

    },

  }
</script>

<style scoped lang="scss">
  .bg{
    background-image: url("../../assets/images/login/zhucebg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    padding: 50px;
  }

  .center{
    width: 570px;
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e7e9eb;
    border-radius: 10px;
    box-shadow: 0px 3px 20px 0px rgba(205,205,205,0.30);
  }
</style>
